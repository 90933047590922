import api from './baseApiService';

class ResourceService {
  async getResources() {
    return await api.get('resources');
  }

  async getResourceById(id) {
    return await api.get(`resource/${id}`);
  }

  async getPatientResources(patientId) {
    return await api.get(`patientresource/${patientId}`);
  }

  async createResource(data) {
    return await api.post('resource/', data);
  }

  async updateResource(id, data) {
    return await api.put(`resource/${id}/update`, data);
  }

  async updatePatientResource(patientId, data) {
    return await api.put(`patientresource/${patientId}/`, data);
  }

  async deleteResource(id) {
    return await api.delete(`resource/${id}/delete`);
  }

  async getResourceUrl(id) {
    return await api.get(`resource/${id}/url`);
  }
}

export default new ResourceService();
