<template>
  <div>
    <div v-for="(data, indexData) in showMoreAlerts ? patientData.alertsByDatetime : getFirstAlerts()" :key="indexData">
      <div class="text-body-2 font-italic mt-2">{{ getAlertDatetime(data.alertDatetime) }}</div>

      <div
        v-for="(alert, indexAlert) in data.alerts"
        :key="indexAlert"
        class="d-flex align-end mr-4"
        :class="
          patientData.alertsByDatetime.some((x) => x.alerts.some((y) => y.alert.type === 'variation'))
            ? 'width-280'
            : patientData.alertsByDatetime.some((x) =>
                x.alerts.some((y) => y.activityType === activityTypes.APR || y.activityType === activityTypes.GLY)
              )
            ? 'width-260'
            : [activityTypes.FDE].includes(alert.activityType)
            ? 'width-180'
            : [activityTypes.INC].includes(alert.activityType)
            ? getLanguage() === 'en' &&
              !patientData.alertsByDatetime.every((x) => x.alerts.every((y) => y.alert.status === statusLevels.ERR))
              ? 'width-230'
              : 'width-165'
            : patientData.alertsByDatetime.every((x) => x.alerts.every((y) => y.activityType === activityTypes.SAT))
            ? 'width-150'
            : 'width-220'
        "
      >
        <v-btn
          text
          block
          elevation="0"
          small
          class="no-text-transform pl-0 pr-1 d-flex"
          :class="alert.alert.isPriorityAlert ? 'py-6' : 'py-0'"
          @click="viewPatientMonitoringDetail(patientData.patient.patientId, alert)"
        >
          <div class="d-flex flex-column align-start justify-start full-width">
            <div v-if="alert.alert.isPriorityAlert">
              <span class="text-caption red--text font-weight-bold">{{ $t('priorityAlert') }}</span>
            </div>

            <div class="d-flex align-center justify-space-between full-width">
              <div class="text-body-2 text--secondary">
                {{
                  $t(
                    `${
                      alert.activityType === activityTypes.FDE
                        ? 'Fall'
                        : alert.activityType === activityTypes.INC
                        ? 'Incontinence'
                        : alert.alert.monitoringValue
                    }AlertRealtimeName`
                  ) + (getLanguage() === 'fr' ? ' :' : ':')
                }}
              </div>

              <div class="d-flex align-center">
                <div v-if="alert.alert.type == 'variation'" class="text-body-1 text--primary font-weight-bold">
                  {{
                    $t('patientMonitoringAlert.variation') +
                    ' ' +
                    (alert.alert.pastDataAverage - alert.alert.periodAverage).toFixed(
                      [activityTypes.BDT, activityTypes.GLY].includes(alert.activityType) ? 1 : 0
                    ) +
                    ([activityTypes.SAT, activityTypes.BDT].includes(alert.activityType) ? '' : ' ') +
                    $t(`${alert.activityType}Unit`)
                  }}
                </div>

                <div
                  v-else-if="alert.activityType === activityTypes.FDE"
                  class="text-body-1 text--primary font-weight-bold"
                >
                  {{
                    alert.alert.type === 'error' ? $t('error') : alert.alert.type === 'fall' ? $t('fallDetected') : ''
                  }}
                </div>

                <div
                  v-else-if="alert.activityType === activityTypes.INC"
                  class="text-body-1 text--primary font-weight-bold"
                >
                  <div>
                    {{
                      alert.alert.error
                        ? $t('error')
                        : alert.alert.status === statusLevels.WE
                        ? $t('Wet')
                        : alert.alert.status === statusLevels.CH
                        ? $t('Change')
                        : ''
                    }}
                  </div>
                </div>

                <template v-else>
                  <div
                    v-for="(value, valueIndex) in alert.alert.values"
                    :key="valueIndex"
                    class="text-body-1 text--primary"
                  >
                    <span
                      :class="
                        value.monitoringValue === alert.alert.monitoringValue ? ' text--primary font-weight-bold' : ''
                      "
                    >
                      {{ value.value }}</span
                    ><span>{{ valueIndex !== alert.alert.values.length - 1 ? '/' : '' }}</span>
                  </div>

                  <span v-if="![activityTypes.SAT, activityTypes.BDT].includes(alert.activityType)">&nbsp;</span
                  ><span class="text-body-1 text--primary font-weight-bold">{{ $t(`${alert.activityType}Unit`) }}</span>
                </template>
              </div>
            </div>
          </div>
        </v-btn>

        <v-tooltip right class="p-6">
          <template #activator="{ on, attrs }">
            <v-icon class="ml-2 margin-bottom-6" v-bind="attrs" small icon v-on="on"> mdi-information-outline </v-icon>
          </template>

          <v-row align="center">
            <v-col class="py-4">
              <template v-if="alert.activityType === activityTypes.FDE">
                <span v-if="alert.alert.type === 'error'">
                  {{ $t(alertMessage('signalLost')) }}
                </span>

                <span v-else-if="alert.alert.type === 'fall'">
                  {{ $t('patientMonitoringAlert.fallHasBeenDetected') }}
                </span>
              </template>

              <template v-else-if="alert.activityType === activityTypes.INC">
                <span v-if="alert.alert.error">
                  {{ $t('error') + (getLanguage() === 'fr' ? ' : ' : ': ') + alertMessage(alert.alert.error) }}
                </span>

                <span v-else-if="alert.alert.status === statusLevels.WE">
                  {{ $t('patientMonitoringAlert.wetIncontinence') }}
                </span>

                <span v-else-if="alert.alert.status === statusLevels.CH">
                  {{ $t('patientMonitoringAlert.changeIncontinence') }}
                </span>
              </template>

              <span v-else-if="alert.alert.type === 'variation'">
                <div>
                  {{
                    $t('variationEquivalentTo')
                      .replace('{activityTypeName}', $t(alert.activityType + 'FullName').toLowerCase())
                      .replace(
                        '{value}',
                        (alert.alert.pastDataAverage - alert.alert.periodAverage).toFixed(
                          [activityTypes.BDT, activityTypes.GLY].includes(alert.activityType) ? 1 : 0
                        )
                      )
                      .replace(
                        '{space}',
                        [activityTypes.SAT, activityTypes.BDT].includes(alert.activityType) ? '' : ' '
                      )
                      .replace('{unit}', $t(`${alert.activityType}Unit`))
                  }}
                </div>
                <div>
                  {{
                    $t('variationThreshold')
                      .replace('{value}', alert.alert.threshold)
                      .replace('{unit}', $t(alert.alert.unitType))
                      .replace('{space}', ['celsius', 'percentageVariation'].includes(alert.alert.unitType) ? '' : ' ')
                      .replace(
                        '{beforeValue}',
                        alert.alert.length > 1 ? $t('ofDaysPlural').toLowerCase() : $t('ofDaysSingular').toLowerCase()
                      )
                      .replace(
                        '{daysValue}',
                        alert.alert.length > 1 ? $t('lastDays').replace('{value}', alert.alert.length) : $t('lastDay')
                      )
                  }}
                </div>
              </span>

              <span v-else>
                {{
                  $t(`${alert.alert.type}Threshold`).replace('{threshold}', alert.alert.threshold) +
                  ([activityTypes.SAT, activityTypes.BDT].includes(alert.activityType) ? '' : ' ') +
                  $t(alert.alert.unitType)
                }}</span
              >
            </v-col>
          </v-row>
        </v-tooltip>
      </div>
    </div>

    <v-btn
      v-if="patientData.alertsByDatetime.flatMap((x) => x.alerts).length > 5"
      class="mt-2"
      x-small
      color="primary"
      @click="showMoreAlerts = !showMoreAlerts"
    >
      {{
        showMoreAlerts
          ? $t('patientMonitoringAlert.showLess')
          : $t('patientMonitoringAlert.showNextAlerts') +
            ` (+${
              patientData.alertsByDatetime.flatMap((x) => x.alerts).length -
              getFirstAlerts().flatMap((x) => x.alerts).length
            })`
      }}
    </v-btn>
  </div>
</template>

<script>
import { ActivityTypes, StatusLevels } from '@/components/PatientMonitoring/constants.js';
import translationMixin from '@/translationMixin';
import monitoringAlertRealtimeMixin from '@/components/PatientMonitoringAlertRealtime/monitoringAlertRealtimeMixin';

export default {
  name: 'MonitoringAlertRealtimeContentDetails',
  mixins: [monitoringAlertRealtimeMixin, translationMixin],
  props: {
    patientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activityTypes: ActivityTypes,
      statusLevels: StatusLevels,
      alertVisibleCount: 5,
      showMoreAlerts: false,
      timerToCheckCallbackRequests: null,
    };
  },

  methods: {
    alertMessage(errorType) {
      const camelCaseErrorType = this.toCamelCase(errorType);
      return this.$t(`iotRealtime.status.${camelCaseErrorType}`);
    },
    toCamelCase(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    },
    getFirstAlerts: function () {
      const filteredObjects = this.patientData.alertsByDatetime.reduce((acc, obj) => {
        const totalLength = acc.reduce((sum, item) => sum + item.alerts.length, 0);

        if (totalLength >= this.alertVisibleCount) {
          return acc;
        }

        const remainingSpace = this.alertVisibleCount - totalLength;
        const alertsToAdd = obj.alerts.slice(0, remainingSpace);
        return [...acc, { ...obj, alerts: alertsToAdd }];
      }, []);

      return filteredObjects;
    },

    viewPatientMonitoringDetail: function (patientId, alert) {
      this.$emit('viewMonitoringDetail', patientId, alert);
    },
  },
};
</script>

<style scoped>
.width-280 {
  width: 280px;
}

.width-260 {
  width: 260px;
}

.width-230 {
  width: 230px;
}

.width-220 {
  width: 220px;
}

.width-165 {
  width: 165px;
}

.width-150 {
  width: 150px;
}

.width-230 {
  width: 230px;
}

.width-180 {
  width: 180px;
}

.full-width {
  width: 100%;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.no-text-transform {
  text-transform: none;
}
</style>
