import api from './baseApiService';

class LogService {
  async getLogs(dateFrom, dateTo, patientIds, userIds) {
    let url = 'logs';
    let params = new URLSearchParams();

    if (dateFrom) params.append('dateFrom', dateFrom);
    if (dateTo) params.append('dateTo', dateTo);

    if (patientIds?.length) {
      params.append('patientIds', patientIds.join(','));
    }
    if (userIds?.length) {
      params.append('userIds', userIds.join(','));
    }

    const fullUrl = `${url}?${params.toString()}`;

    return await api.get(fullUrl);
  }

  async getDetailLog(requestId, eventDatetime) {
    let url = `logrequestdetails`;
    let params = new URLSearchParams();

    if (requestId) params.append('requestId', requestId);
    if (eventDatetime) params.append('logDate', eventDatetime);

    const fullUrl = `${url}?${params.toString()}`;

    return await api.get(fullUrl);
  }

  async getLogsFilters() {
    return await api.get('logsfilters');
  }
}

export default new LogService();
