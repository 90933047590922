import { ActivityTypes, StatusLevels } from '@/components/PatientMonitoring/constants';

export const RealtimeDataTypeCodes = {
  WEI: ActivityTypes.WEI,
  APR: ActivityTypes.APR,
  SAT: ActivityTypes.SAT,
  CFR: ActivityTypes.CFR,
  INC: ActivityTypes.INC,
  FDE: ActivityTypes.FDE,
  BDT: ActivityTypes.BDT,
  RES: ActivityTypes.RES,
  GLY: ActivityTypes.GLY,
  ECG: 'ECG',
};

export const RealtimeVitalSignsCodes = [
  ActivityTypes.APR,
  ActivityTypes.CFR,
  ActivityTypes.SAT,
  ActivityTypes.RES,
  ActivityTypes.BDT,
];

export const IotStateEventCodes = {
  APP_CRASHED: 'AppCrashed',
  FALL_DETECTED: 'FallDetected',
  FALL_MODE: 'FallMode',
  MOTION_MODE: 'MotionMode',
  CAMERA_FEED_FAIL: 'CameraFeedFail',
  PEOPLE_MODE: 'PeopleMode',
  RUNNING: 'Running',
  CAMERA_FEED_SUCCESS: 'CameraFeedSuccess',
  CALCULATION_ERROR: 'CalculationError',
  FAULTY: 'Faulty',
  NO_TRANSMITTER: 'NoTransmitter',
  SIGNAL_LOST: 'SignalLost',
  STRIP_TRANSMITTER: 'StripDetachedFromTransmitter',
  STRIP_BRIEF: 'StripDetachedFromBrief',
};

export const IotStateEventWarningCodes = [StatusLevels.WE];

export const IotStateEventAlertCodes = [IotStateEventCodes.FALL_DETECTED, StatusLevels.CH];

export const IotStateEventErrorCodes = [
  IotStateEventCodes.APP_CRASHED,
  IotStateEventCodes.CAMERA_FEED_FAIL,

  IotStateEventCodes.SIGNAL_LOST,
  IotStateEventCodes.CALCULATION_ERROR,
  IotStateEventCodes.FAULTY,
  IotStateEventCodes.NO_TRANSMITTER,
  IotStateEventCodes.STRIP_TRANSMITTER,
  IotStateEventCodes.STRIP_BRIEF,
];
