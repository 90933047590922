<template>
  <v-container>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <PageTitle :title="$t('log.logs')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <LogFilter :is-disabled="isProcessing" @filter="onApplyFilter" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DataGrid
          ref="dataGrid"
          :items="logs"
          :columns="columns"
          grid-name="log_grid"
          :show-select="false"
          :items-per-page="25"
          :data-loading="showWaitModal"
        >
          <template #item.actions="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :ref="`view_${item.request_id}`"
                  @click="viewLogDetailAction(item.request_id, item.logDate)"
                >
                  <v-list-item-icon><v-icon>mdi-eye</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('view') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <LogModal
      v-if="showEditModal"
      :show.sync="showEditModal"
      :request-id="selectedLogId"
      :event-datetime="selectedEventDatetime"
    />
  </v-container>
</template>

<script>
import translationMixin from '@/translationMixin';
import DataGrid from '@/components/DataGrid.vue';
import LogModal from './LogModal.vue';
import LogFilter from './LogFilter.vue';
import LogService from '@/services/logService';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'LogGrid',
  components: {
    DataGrid,
    LogFilter,
    LogModal,
  },
  mixins: [translationMixin, permissionMixin],

  data() {
    return {
      showEditModal: false,
      error: null,
      selectedLogId: null,
      selectedEventDatetime: null,
      isProcessing: false,
      showWaitModal: false,
      logs: [],
      filters: {
        dateFrom: null,
        dateTo: null,
        patientIds: null,
        userIds: null,
      },
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'log.logDate',
          value: 'logDate',
          filterable: true,
        },
        {
          text: 'log.username',
          value: 'username',
          filterable: true,
        },
        {
          text: 'log.userLastFirstNames',
          value: 'userLastFirstNames',
          filterable: true,
        },
        {
          text: 'log.context',
          value: 'context',
          filterable: true,
        },
        {
          text: 'log.data',
          value: 'data',
          filterable: true,
        },
        {
          text: 'log.operation',
          value: 'operation',
          filterable: true,
        },
        {
          text: 'log.actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        },
      ];

      return columns;
    },
  },

  methods: {
    async loadLogs() {
      this.isProcessing = true;
      this.logs = [];
      this.selectedLogId = null;
      this.selectedEventDatetime = null;
      this.showWaitModal = true;

      try {
        let logs = await LogService.getLogs(
          this.filters.dateFrom,
          this.filters.dateTo,
          this.filters.patientIds,
          this.filters.userIds
        );
        this.logs = logs.map((item) => {
          let result = { ...item };
          if (result.operation) {
            result.operation = this.$t(`log.operation.` + result.operation);
          }
          if (result.context) {
            result.context = this.$t(`log.context.` + result.context);
          }
          return result;
        });
      } catch (error) {
        this.error = error;
      } finally {
        this.isProcessing = false;
      }
      this.showWaitModal = false;
    },

    viewLogDetailAction: function (id, eventDatetime) {
      this.selectedLogId = id;
      this.selectedEventDatetime = eventDatetime;
      this.showEditModal = true;
    },

    async onApplyFilter(filters) {
      this.filters = filters;
      this.isProcessing = true;
      this.loadLogs();
    },
  },
};
</script>

<style scoped></style>
