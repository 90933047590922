<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog
      v-if="show"
      ref="dialogRef"
      value="show"
      scrollable
      persistent
      :max-width="accessGroupId != null ? '1000px' : '800px'"
    >
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <v-card-text>
          <v-row>
            <v-col>
              <v-tabs v-model="selectedTab" background-color="transparent">
                <v-tab> {{ $t('accessGroup.generalTab') }} </v-tab>
                <v-tab> {{ $t('accessGroup.permissions') }} </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tabs-items v-model="selectedTab" class="activity-tabs">
                <v-tab-item>
                  <v-form ref="accessGroupModalForm" lazy-validation>
                    <v-row>
                      <v-col cols="12" :md="accessGroupId != null ? 8 : 12">
                        <v-text-field
                          :id="getIdByName('name')"
                          ref="name"
                          v-model="formData.name"
                          filled
                          :label="$t('name')"
                          class="required-indicator"
                          :rules="[validationRules.required]"
                          :disabled="formData.system"
                        />
                        <v-textarea
                          ref="description"
                          v-model="formData.description"
                          height="135"
                          :label="$t('description')"
                          filled
                          :rules="[validationRules.required]"
                          class="required-indicator"
                          :disabled="formData.system"
                        />
                      </v-col>

                      <v-col v-if="accessGroupId !== null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                        <detail-field
                          :id="getIdByName('createdDate')"
                          ref="createdDate"
                          v-model="formData.createdDate"
                          :label="$t('createdDate')"
                        />

                        <detail-field
                          :id="getIdByName('createdBy')"
                          ref="createdBy"
                          v-model="formData.createdBy"
                          :label="$t('createdBy')"
                        />

                        <detail-field
                          :id="getIdByName('modifiedDate')"
                          ref="modifiedDate"
                          v-model="formData.modifiedDate"
                          :label="$t('modifiedDate')"
                        />

                        <detail-field
                          :id="getIdByName('modifiedBy')"
                          ref="modifiedBy"
                          v-model="formData.modifiedBy"
                          :label="$t('modifiedBy')"
                        />
                      </v-col>
                    </v-row>
                    <RequiredFieldsLegend />
                  </v-form>
                </v-tab-item>
                <v-tab-item>
                  <v-row dense>
                    <v-col cols="auto">
                      <v-btn @click="toggleExpand()">
                        {{ openedItems.length === 0 ? $t('expandAll') : $t('collapseAll') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense
                    ><v-col>
                      <v-treeview
                        ref="treeview"
                        v-model="formData.selectedPermissions"
                        dense
                        :disabled="formData.system"
                        selected-color="primary"
                        selectable
                        :items="items"
                        item-children="children"
                        item-text="text"
                        item-key="id"
                        :open.sync="openedItems"
                        open-on-click
                        hoverable
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ formData.system ? $t('close') : $t('cancel') }}
          </v-btn>
          <SaveButton
            v-if="!formData.system"
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import SaveButton from '@/components/SaveButton.vue';
import accessGroupService from '@/services/accessGroupService';

export default {
  name: 'AccessGroupModal',

  components: { SaveButton },

  mixins: [translation, accessibility, validationRulesMixin],

  props: {
    accessGroupId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      formData: {
        name: '',
        description: '',
        system: false,
        selectedPermissions: [],
      },
      originalFormData: {},
      items: [],
      openedItems: [],
      selectedTab: 0,
    };
  },
  computed: {
    title() {
      if (this.accessGroupId) {
        return this.formData.system ? this.$t('accessGroup.view') : this.$t('accessGroup.update');
      }
      return this.$t('accessGroup.create');
    },
  },
  watch: {
    show: async function () {
      if (this.show) {
        this.init();
        await this.buildAccessTree();
        if (this.accessGroupId != null) {
          this.editClicked();
        }
      }
    },
  },

  created: function () {},
  methods: {
    async init() {
      this.selectedTab = 0;

      this.formData.name = '';
      this.formData.description = '';
      this.formData.selectedPermissions = [];
      this.formData.system = false;
      this.formData.createdDate = '';
      this.formData.createdBy = '';
      this.formData.modifiedDate = '';
      this.formData.modifiedBy = '';

      this.openedItems = [];
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    async editClicked() {
      this.showWaitModal = true;

      try {
        this.openedItems = [];

        let accessGroup = await accessGroupService.getAccessGroupById(this.accessGroupId);
        this.formData.name = accessGroup.name;
        this.formData.description = accessGroup.description;
        this.formData.createdDate = accessGroup.createdAt;
        this.formData.createdBy = accessGroup.createdBy.username;
        this.formData.modifiedDate = accessGroup.updatedAt;
        this.formData.modifiedBy = accessGroup.updatedBy.username;
        this.formData.system = accessGroup.system;
        this.formData.selectedPermissions = accessGroup.permissions;
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async createOrUpdateAction() {
      let isValid = this.$refs.accessGroupModalForm.validate();

      if (!isValid) {
        this.selectedTab = 0;
      }

      if (!isValid || this.isProcessing) return;

      let data = {
        name: this.formData.name,
        description: this.formData.description,
        permissions: this.formData.selectedPermissions,
      };

      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        if (this.accessGroupId) {
          await accessGroupService.updateAccessGroup(this.accessGroupId, data);
        } else {
          await accessGroupService.createAccessGroup(data);
        }
        this.closeDialog();
        this.$emit('refresh');
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },

    async buildAccessTree() {
      const permissions = await accessGroupService.getAllowedPermissions();
      const permissionsRegEx = /(?<appname>[^.]*)\.(?<action>[^_]*)_(?<subject>.*)/;

      const nodes = permissions
        .map((permission) => {
          // the following are special and must be put in a "Global" parent
          if (permission === 'virtuose.manage_normative') {
            return {
              id: permission,
              subject: this.$t('accessGroup.permissionSubject.global'),
              action: this.$t('accessGroup.permissionAction.manageNormative'),
              text: this.$t('accessGroup.permissionAction.manageNormative'),
              selected: false,
            };
          }
          if (permission === 'virtuose.can_view_all_patients') {
            return {
              id: permission,
              subject: this.$t('accessGroup.permissionSubject.global'),
              action: this.$t('accessGroup.permissionAction.viewAllPatients'),
              text: this.$t('accessGroup.permissionAction.viewAllPatients'),
              selected: false,
            };
          }
          if (permission === 'virtuose.manage_ignore_idle_timeout') {
            return {
              id: permission,
              subject: this.$t('accessGroup.permissionSubject.global'),
              action: this.$t('accessGroup.permissionAction.ignoreIdleTimeout'),
              text: this.$t('accessGroup.permissionAction.ignoreIdleTimeout'),
              selected: false,
            };
          }

          let match = permission.match(permissionsRegEx);
          if (match && match.groups?.appname && match.groups?.action && match.groups?.subject) {
            return {
              id: permission,
              subject: this.$t(`accessGroup.permissionSubject.${match.groups.appname}.${match.groups.subject}`),
              action: this.$t(`accessGroup.permissionAction.${match.groups.action}`),
              text: this.$t(`accessGroup.permissionAction.${match.groups.action}`),
              selected: false,
            };
          }
        })
        .filter((x) => !!x);

      const subjects = [...new Set(nodes.map((x) => x.subject))].sort((a, b) => a.localeCompare(b));
      const subjectNodes = subjects.map((parent) => {
        return {
          id: `parent_${parent}`,
          text: parent,
          children: nodes.filter((x) => x.subject === parent).sort((a, b) => a.text.localeCompare(b.text)),
        };
      });

      this.items = subjectNodes;
    },
    toggleExpand() {
      if (this.openedItems.length > 0) {
        this.openedItems = [];
      } else {
        this.openedItems = this.items.map((x) => x.id);
      }
    },
  },
};
</script>

<style scoped></style>
