<template>
  <v-container>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row align="center">
      <v-col>
        <PageTitle :title="$t('patientMonitoringAlert.title')" />
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <v-text-field
          v-model="searchValue"
          :disabled="showWaitModal"
          dense
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          clearable
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-btn ref="filtersIcon" :disabled="showWaitModal" icon @click="showFilterSheet = !showFilterSheet">
          <v-icon v-if="filters.statusLevelIds.length === 0 && filters.cohortIds.length === 0">mdi-filter</v-icon>
          <v-icon v-else color="primary">mdi-filter-check</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="!showWaitModal">
      <v-col>
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="(panel, indexPanel) in panelsLists" :key="indexPanel">
            <v-expansion-panel-header>
              <PatientMonitoringAlertHeaderRealtime
                :vital-signs-alert-count="panel.dataList.header"
                :title="panel.title"
              ></PatientMonitoringAlertHeaderRealtime>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <PatientMonitoringAlertContentRealtime
                :patient-alerts="panel.dataList.content"
                :status-levels-for-activity-type="panel.statusList"
                :title="panel.title"
              ></PatientMonitoringAlertContentRealtime>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-navigation-drawer v-model="showFilterSheet" fixed temporary right touchless>
      <v-card flat>
        <v-card-title>{{ $t('filters') }}</v-card-title>
        <v-card-text>
          <PatientMonitoringAlertFilterRealtime :filters="filters" @filter="updateGridConfiguration" />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import PatientMonitoringAlertContentRealtime from './Content/PatientMonitoringAlertContentRealtime';
import PatientMonitoringAlertFilterRealtime from './PatientMonitoringAlertFilterRealtime';
import PatientMonitoringAlertHeaderRealtime from './Header/PatientMonitoringAlertHeaderRealtime';

import patientMonitoringAlertService from '@/services/patientMonitoringAlertService';
import translationMixin from '@/translationMixin';
import wsNotificationsMixin from '@/wsNotificationsMixin';

import { RealtimeVitalSignsCodes } from '../PatientIotRealtime/constants';
import { ActivityTypes, StatusLevels } from '../PatientMonitoring/constants';
import { containsString } from '@/utils/stringUtils';
import userService from '@/services/userService';

export default {
  name: 'PatientMonitoringAlertRealtime',
  components: {
    PatientMonitoringAlertContentRealtime,
    PatientMonitoringAlertFilterRealtime,
    PatientMonitoringAlertHeaderRealtime,
  },
  mixins: [translationMixin, wsNotificationsMixin],

  data() {
    return {
      gridName: 'monitoring_alert_realtime_grid',
      timer: null,
      showFilterSheet: false,
      showWaitModal: false,
      error: null,
      filters: {
        statusLevelIds: [],
        cohortIds: [],
      },
      searchValue: '',
      patientMonitoringAlerts: [],
      alertCountByActivityType: {},

      vitalSignsCodeToMonitoringValue: {
        [ActivityTypes.SAT]: ['Saturation'],
        [ActivityTypes.CFR]: ['Pulse'],
        [ActivityTypes.RES]: ['RespiratoryRate'],
        [ActivityTypes.APR]: ['Systolic', 'Diastolic'],
        [ActivityTypes.BDT]: ['BodyTemperature'],
        [ActivityTypes.GLY]: ['CapillaryGlycemia'],
      },

      observationTypeToMonitoringValue: {
        Saturation: 'SAT',
        Pulse: 'BeatsPerMin',
        Diastolic: 'DAPR',
        Systolic: 'SAPR',
        RespiratoryRate: 'Breathing',
        BodyTemperature: 'BodyTemperature',
        CapillaryGlycemia: 'CapillaryGlycemia',
      },

      statusLevelsVitalSigns: [
        {
          statusLevelCode: StatusLevels.AL,
          statusLevelTranslation: 'patientMonitoringAlert.alert',
          icon: 'mdi-alert-circle',
          iconColor: 'red',
        },
      ],
      statusLevelsGLY: [
        {
          statusLevelCode: StatusLevels.AL,
          statusLevelTranslation: 'patientMonitoringAlert.alert',
          icon: 'mdi-alert-circle',
          iconColor: 'red',
        },
      ],
      statusLevelsFDE: [
        {
          statusLevelCode: StatusLevels.AL,
          statusLevelTranslation: 'patientMonitoringAlert.fall',
          icon: 'mdi-alert-circle',
          iconColor: 'red',
        },
        {
          statusLevelCode: StatusLevels.WA,
          statusLevelTranslation: 'patientMonitoringAlert.error',
          icon: 'mdi-alert',
          iconColor: 'orange',
        },
      ],

      statusLevelsINC: [
        {
          statusLevelCode: StatusLevels.ERR,
          icon: 'mdi-alert',
          iconColor: 'red',
        },
        {
          statusLevelCode: StatusLevels.CH,
          icon: '/static/incontinenceicon/change_essity.svg',
        },
        {
          statusLevelCode: StatusLevels.WE,
          icon: 'mdi-check-circle',
          iconColor: '#F2BA4C',
        },
      ],
    };
  },
  computed: {
    vitalSignsAlerts() {
      return this.filterActivityTypeData(RealtimeVitalSignsCodes, this.statusLevelsVitalSigns);
    },
    capillaryGlycemiaAlerts() {
      return this.filterActivityTypeData([ActivityTypes.GLY], this.statusLevelsGLY);
    },
    fallDetectionAlerts() {
      return this.filterActivityTypeData([ActivityTypes.FDE], this.statusLevelsFDE);
    },
    incontinenceAlerts() {
      return this.filterActivityTypeData([ActivityTypes.INC], this.statusLevelsINC);
    },
    panelsLists() {
      return [
        { dataList: this.vitalSignsAlerts, statusList: this.statusLevelsVitalSigns, title: 'vitalSigns' },
        { dataList: this.capillaryGlycemiaAlerts, statusList: this.statusLevelsGLY, title: 'capillaryGlycemia' },
        { dataList: this.fallDetectionAlerts, statusList: this.statusLevelsFDE, title: 'fallDetection' },
        { dataList: this.incontinenceAlerts, statusList: this.statusLevelsINC, title: 'incontinence' },
      ];
    },
  },

  async created() {
    this.showWaitModal = true;

    this.subscribeToPatientMonitoringAlertRealtime();

    // I will put both calls in a Promise.all in the 1919 PR when this one will be merged
    const userGridConfigData = await userService.getUserGridConfiguration(this.gridName);

    if (userGridConfigData?.configuration) {
      this.filters = userGridConfigData.configuration;
    }

    await this.getPatientMonitoringAlerts();

    this.showWaitModal = false;
  },

  methods: {
    onPatientMonitoringAlertRealtimeData: function (webSocketData) {
      const newMonitoringAlert = webSocketData.data?.newMonitoringAlert;

      if (newMonitoringAlert) {
        this.addNewAlert(newMonitoringAlert);
      }

      const resolvedMonitoringAlerts = webSocketData.data?.resolvedMonitoringAlerts;

      if (resolvedMonitoringAlerts) {
        this.patientMonitoringAlerts.forEach((patientMonitoringAlert) => {
          this.removeResolvedAlerts(patientMonitoringAlert, webSocketData.data?.resolvedMonitoringAlerts);
        });
      }
    },

    addNewAlert: function (newMonitoringAlert) {
      const patientData = {
        alert: newMonitoringAlert.alert,
        patient: newMonitoringAlert.patient,
      };

      const activityTypeIndex = this.patientMonitoringAlerts.findIndex(
        (x) => x.activityType === newMonitoringAlert.activityTypeCode
      );

      if (activityTypeIndex >= 0) {
        const alertStatus = this.patientMonitoringAlerts[activityTypeIndex].patientsByStatus.findIndex(
          (x) => x.statusLevelId === newMonitoringAlert.statusLevelId
        );

        if (alertStatus >= 0) {
          this.patientMonitoringAlerts[activityTypeIndex].patientsByStatus[alertStatus].patients.push(patientData);
        } else {
          this.patientMonitoringAlerts[activityTypeIndex].patientsByStatus.push({
            statusLevelId: newMonitoringAlert.statusLevelId,
            statusLevelCode: newMonitoringAlert.statusLevelCode,
            statusLevelPriority: newMonitoringAlert.statusLevelPriority,
            patients: [patientData],
          });
        }
      } else {
        this.patientMonitoringAlerts.push({
          activityType: newMonitoringAlert.activityTypeCode,
          patientsByStatus: [
            {
              statusLevelId: newMonitoringAlert.statusLevelId,
              statusLevelCode: newMonitoringAlert.statusLevelCode,
              statusLevelPriority: newMonitoringAlert.statusLevelPriority,
              patients: [patientData],
            },
          ],
        });
      }
    },

    removeResolvedAlerts: function (patientMonitoringAlert, resolvedMonitoringAlerts) {
      resolvedMonitoringAlerts?.forEach((wsMonitoringAlert) => {
        const resolvedMonitoringAlertId = parseFloat(wsMonitoringAlert.id);

        patientMonitoringAlert.patientsByStatus.forEach((y) => {
          y.patients.forEach((data, patientIndex) => {
            const currentMonitoringAlertId = parseFloat(data.alert.patientMonitoringAlertId);

            if (
              currentMonitoringAlertId === resolvedMonitoringAlertId &&
              currentMonitoringAlertId > 0 &&
              resolvedMonitoringAlertId > 0
            ) {
              y.patients.splice(patientIndex, 1);
            }
          });
        });
      });
    },

    getPatientMonitoringAlerts: async function () {
      try {
        this.patientMonitoringAlerts = await patientMonitoringAlertService.getPatientMonitoringAlerts();

        this.patientMonitoringAlerts.forEach((patientMonitoringAlert, ind) => {
          if (patientMonitoringAlert.activityType === ActivityTypes.INC) {
            this.statusLevelsINC.forEach((statusLevel) => {
              const exists = patientMonitoringAlert.patientsByStatus.some(
                (item) => item.statusLevelCode === statusLevel.statusLevelCode
              );

              if (!exists) {
                this.patientMonitoringAlerts[ind].patientsByStatus.push({
                  statusLevelId: null,
                  statusLevelCode: statusLevel.statusLevelCode,
                  statusLevelPriority: statusLevel.statusLevelPriority,
                  patients: [],
                });
              }
            });
          }
        });
      } catch (error) {
        this.error = error;
      }
    },

    filterActivityTypeData: function (activityTypeCodes, statusLevelsForActivityType) {
      const items = this.patientMonitoringAlerts

        .filter((data) => activityTypeCodes.includes(data.activityType))
        .map((item) => ({
          ...item,
          patientsByStatus: item.patientsByStatus
            .filter((p) => {
              return this.filters?.statusLevelIds?.length > 0
                ? this.filters.statusLevelIds.includes(p.statusLevelId)
                : true;
            })
            .map((x) => ({
              ...x,
              patients: this.filterPatients(x.patients),
            })),
        }));

      const areVitalSignsData = activityTypeCodes.some((x) => RealtimeVitalSignsCodes.includes(x));

      const alertRealtimeHeader = areVitalSignsData
        ? this.getVitalSignsAlertCountByActivityType(items)
        : this.getAlertCountByActivityType(items, statusLevelsForActivityType);

      const alertRealtimeContent = this.filterPatientMonitoringAlerts(items);

      return {
        header: alertRealtimeHeader,
        content: alertRealtimeContent,
      };
    },

    filterPatientMonitoringAlerts: function (items) {
      const patientIds = [
        ...new Set(items.flatMap((x) => x.patientsByStatus.flatMap((y) => y.patients.map((u) => u.patient.patientId)))),
      ];

      const patientAlerts = patientIds.map((patientId) => {
        const patientItems = items.filter((item) =>
          item.patientsByStatus.some((x) => x.patients.some((x) => x.patient.patientId === patientId))
        );

        let orderedPatientVitalSignsAlerts = [];

        patientItems.forEach((item) => {
          item.patientsByStatus.forEach((status) => {
            const allPatientAlerts = status.patients.filter((patient) => patient.patient.patientId === patientId);

            allPatientAlerts.forEach((patientAlert) => {
              const alertData = patientAlert.alert;
              const index = orderedPatientVitalSignsAlerts.findIndex(
                (x) =>
                  x.alertDatetime === alertData?.dataDatetime ||
                  new Date(x.alertDatetime)?.setSeconds(0) == new Date(alertData?.dataDatetime)?.setSeconds(0)
              );

              const activityTypeCode = item.activityType;
              const monitoringValues = this.vitalSignsCodeToMonitoringValue[activityTypeCode];

              const alertDetails = {
                patientMonitoringAlertId: alertData.patientMonitoringAlertId,
                activityId: alertData.activityId,
                activityType: activityTypeCode,
                statusLevel: {
                  code: status.statusLevelCode,
                  priority: status.statusLevelPriority,
                },
                alert: [ActivityTypes.FDE, ActivityTypes.INC].includes(activityTypeCode)
                  ? alertData.details
                  : {
                      values: monitoringValues.map((value) => ({
                        monitoringValue: this.observationTypeToMonitoringValue[value],
                        value: alertData.details?.[value],
                      })),
                      threshold: alertData.details?.alert?.threshold,
                      length: alertData.details?.alert?.length,
                      unitType: alertData.details?.alert?.unitType,
                      type: alertData.details?.alert?.type,
                      monitoringValue: alertData.details?.alert?.monitoringValue,
                      isPriorityAlert:
                        !!alertData.details?.alert?.isPriorityAlert || !!alertData.details?.isPriorityAlert,
                      pastDataAverage: alertData.details?.pastDataAverage,
                      periodAverage: alertData.details?.periodAverage,
                      periodEndDatetime: alertData.details?.periodEndDatetime,
                      periodStartDatetime: alertData.details?.periodStartDatetime,
                    },
              };

              if (index >= 0) {
                orderedPatientVitalSignsAlerts[index].alerts.push(alertDetails);
              } else {
                orderedPatientVitalSignsAlerts.push({
                  alertDatetime: alertData?.dataDatetime,
                  alerts: [alertDetails],
                });
              }
            });
          });
        });

        const patient = items
          .flatMap((n) => n.patientsByStatus.flatMap((x) => x.patients))
          .find((y) => y.patient.patientId === patientId)?.patient;

        return {
          patient: patient,
          alertsByDatetime: orderedPatientVitalSignsAlerts.sort(
            (a, b) => new Date(b.alertDatetime) - new Date(a.alertDatetime)
          ),
        };
      });

      return patientAlerts.sort((a, b) => {
        const aHasPriority = a.alertsByDatetime.some((x) => x.alerts.some((y) => !!y.alert.isPriorityAlert));
        const bHasPriority = b.alertsByDatetime.some((x) => x.alerts.some((y) => !!y.alert.isPriorityAlert));

        if (aHasPriority !== bHasPriority) {
          return bHasPriority - aHasPriority;
        }

        return new Date(a.alertsByDatetime.at(-1).alertDatetime) - new Date(b.alertsByDatetime.at(-1).alertDatetime);
      });
    },

    filterPatients: function (patients) {
      return patients
        .filter((patient) => {
          if (!this.searchValue) {
            return true;
          }
          let name = `${patient.patient.lastName}, ${patient.patient.firstName}`;
          let roombed;
          if (patient.patient.roombed) {
            roombed = `${patient.patient.roombed.room}-${patient.patient.roombed.bed}`;
          }
          return containsString(name, this.searchValue) || containsString(roombed, this.searchValue);
        })
        .filter((patient) => {
          if (this.filters?.cohortIds?.length === 0) {
            return true;
          }
          let exists = false;
          patient.patient.cohortIds?.forEach((cohort) => {
            if (!exists) {
              exists = this.filters.cohortIds.some((item) => item === cohort);
            }
          });
          return exists;
        })
        .sort((a, b) => new Date(a.alert.dataDatetime) - new Date(b.alert.dataDatetime));
    },

    getAlertCountByActivityType: function (items, statusLevelsForActivityType) {
      return statusLevelsForActivityType.map((x) => {
        const alertCountByStatusLevelCode = items.flatMap((item) =>
          item.patientsByStatus.filter((y) => y.statusLevelCode === x.statusLevelCode).flatMap((p) => p.patients)
        );

        return {
          alertCount: alertCountByStatusLevelCode?.length || 0,
          activityTypeCode: items?.[0]?.activityType,
          icon: x.icon,
          iconColor: x.iconColor,
          isAnyPriorityAlert: alertCountByStatusLevelCode.some(
            (data) => !!data?.alert?.details?.alert?.isPriorityAlert || !!data?.alert?.details?.isPriorityAlert
          ),
          statusLevelCode: x.statusLevelCode,
        };
      });
    },

    getVitalSignsAlertCountByActivityType: function (items) {
      return RealtimeVitalSignsCodes.map((activityTypeCode) => {
        const activityTypeAlertCount = items
          .filter((item) => item.activityType === activityTypeCode)
          .flatMap((item) => item.patientsByStatus.flatMap((p) => p.patients));

        return {
          alertCount: activityTypeAlertCount?.length || 0,
          activityTypeCode: activityTypeCode,
          isAnyPriorityAlert: activityTypeAlertCount.some((data) => !!data?.alert?.details?.alert?.isPriorityAlert),
        };
      });
    },

    updateGridConfiguration: async function (filters) {
      try {
        await userService.updateUserGridConfiguration(this.gridName, filters);
        this.filters = filters;
      } catch (error) {
        console.log(`Error while saving using configuration in ${'patientMonitoringAlertRealtime'}: ${error}`);
      }
    },
  },
};
</script>
