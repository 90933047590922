import { differenceInMinutes, format } from 'date-fns';
import { ActivityTypes, IOTDeviceTypeCodes } from '@/components/PatientMonitoring/constants.js';
import translationMixin from '@/translationMixin';
import { chartColor } from './Details/chartColor';

import {
  IotStateEventAlertCodes,
  IotStateEventErrorCodes,
  IotStateEventWarningCodes,
} from '@/components/PatientIotRealtime/constants.js';

export default {
  mixins: [translationMixin],

  methods: {
    getIotIconOnDeviceType: function (iotDeviceTypeCode) {
      switch (iotDeviceTypeCode) {
        case IOTDeviceTypeCodes.WATCH:
          return 'mdi-watch-variant';

        default:
          return iotDeviceTypeCode;
      }
    },

    getDetailsActivityTypesColors: function (activityTypeCode) {
      switch (activityTypeCode) {
        case ActivityTypes.APR:
          return chartColor.correctFirstDataColor;
        case ActivityTypes.SAT:
          return 'brown';
        case ActivityTypes.BDT:
          return 'pink';
        case ActivityTypes.RES:
          return 'green';
        case ActivityTypes.CFR:
          return 'grey';
      }
    },

    getDetailsVitalSignsFontColor: function (activityTypeCode) {
      switch (activityTypeCode) {
        case ActivityTypes.APR:
          return 'blue--text';
        case ActivityTypes.CFR:
          return 'green--text';
        case ActivityTypes.SAT:
          return 'orange--text';
        case ActivityTypes.BDT:
          return 'magenta--text';
        case ActivityTypes.RES:
          return 'indigo--text';
      }
    },

    isDelayedValue: function (data, activityTypeCode) {
      const patientObs = data?.patientActivityIotRealtime?.[activityTypeCode];

      return (
        (patientObs?.values && patientObs?.lastDataDatetime !== data?.lastVitalSignsSyncData?.lastDataDatetime) ||
        differenceInMinutes(new Date(), new Date(patientObs?.lastDataDatetime)) > 30
      );
    },

    getFormatDate: function (date) {
      return format(new Date(date), this.getLanguage() === 'fr' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd hh:mm aa');
    },

    getObservationValueTextColor: function (patientIotState) {
      return IotStateEventAlertCodes.includes(patientIotState.values[0])
        ? 'red'
        : IotStateEventErrorCodes.includes(patientIotState.values[0])
        ? 'orange'
        : '#F2BA4C';
    },

    getObservationValueIcon: function (patientIotState) {
      switch (true) {
        case IotStateEventErrorCodes.includes(patientIotState.values[0]):
          return 'mdi-alert';

        case IotStateEventWarningCodes.includes(patientIotState.values[0]):
          return 'mdi-check-circle';

        default:
          return 'mdi-alert-circle';
      }
    },

    getObservationValueIconColor: function (patientIotState) {
      return IotStateEventAlertCodes.includes(patientIotState.values[0])
        ? 'red--text'
        : IotStateEventErrorCodes.includes(patientIotState.values[0])
        ? 'orange--text'
        : IotStateEventWarningCodes.includes(patientIotState.values[0])
        ? 'inc-wet-text-color'
        : '';
    },

    getRelativeDate: function (minutesDifference) {
      if (minutesDifference === 0) {
        return this.$t('iotRealtime.justNow');
      }

      const MINUTES_IN_HOUR = 60;
      const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;

      let timeDifference, timeUnit;

      switch (true) {
        case minutesDifference < MINUTES_IN_HOUR:
          timeDifference = minutesDifference;
          timeUnit = timeDifference === 1 ? this.$t('minute') : this.$t('minutes');
          break;

        case minutesDifference < MINUTES_IN_DAY:
          timeDifference = Math.floor(minutesDifference / MINUTES_IN_HOUR);
          timeUnit = timeDifference === 1 ? this.$t('hour') : this.$t('hours').toLowerCase();
          break;

        default:
          timeDifference = Math.floor(minutesDifference / MINUTES_IN_DAY);
          timeUnit = timeDifference === 1 ? this.$t('day') : this.$t('days').toLowerCase();
      }

      return this.$t('iotRealtime.timeAgo')
        .replace('{timeValue}', timeDifference)
        .replace('{timeUnit}', timeUnit.toLowerCase());
    },
  },
};
