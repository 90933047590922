<template>
  <div>
    <v-snackbar v-if="countdown && countdown > 0" :value="true" top color="primary" :timeout="-1">{{
      $t('idleTimeout.message').replace('{timer}', String(countdown))
    }}</v-snackbar>
  </div>
</template>

<script>
import { useIdle, useStorage, useIntervalFn } from '@vueuse/core';
import { watch, ref, onMounted, getCurrentInstance } from 'vue';
import applicationService from '@/services/applicationService';
import auth from '@/auth/auth';
import { subjects, actions } from '@/auth/permissions';

import translationMixin from '@/translationMixin';
export default {
  components: {},
  mixins: [translationMixin],
  setup: function () {
    /**
     * When a user interact with the application (notified when lastActive is updated) we update a variable in the LocalStorage (via storageLastActive) to indicates the
     * last time the application was active. Since the value is in LocalStorage all opened tabs of the application shares the same variable (so event if a tab is inactive the
     * session will not be ended if another tab is active).
     *
     * A timer checks each second (checkIdle) if the IdleTimeout is almost reached. If we detect that the timeout will be reached in 60 seconds we starts a countdown and
     * when the value reaches 0 we do a signOut.
     */

    const { lastActive } = useIdle(1000, { listenForVisibilityChange: false });
    const storageLastActive = useStorage('lastActive', '');
    const countdown = ref(null);

    const countdownLength = 60000;

    /** Timeout configured in the backend, in milliseconds */
    let idleTimeout = 0;

    watch(
      lastActive,
      () => {
        if (!storageLastActive.value || storageLastActive.value < lastActive.value) {
          storageLastActive.value = lastActive.value;
        }
      },
      { immediate: true }
    );

    const { resume: startIdleCheck, pause: pauseIdleCheck } = useIntervalFn(() => checkIdle(), 1000, {
      immediate: false,
    });

    onMounted(async () => {
      if (getCurrentInstance().proxy.$can(actions.manage, subjects.ignoreIdleTimeout)) {
        return;
      }

      const result = await applicationService.getIdleTimeout();
      idleTimeout = result.idleTimeout * 60 * 1000;
      if (idleTimeout > 0) {
        startIdleCheck();
      }
    });

    function checkIdle() {
      if (!storageLastActive.value || !idleTimeout) {
        return;
      }
      const now = Date.now();
      if (now - storageLastActive.value >= idleTimeout - countdownLength) {
        const expectedLogout = Number(storageLastActive.value) + idleTimeout;
        const secondsBeforeLogout = Math.round((expectedLogout - now) / 1000);
        if (secondsBeforeLogout <= 0) {
          auth.signOut();
          pauseIdleCheck();
        } else {
          countdown.value = secondsBeforeLogout;
        }
      } else {
        countdown.value = null;
      }
    }

    return {
      countdown,
      lastActive,
      storageLastActive,
    };
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
