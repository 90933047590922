<template>
  <div>
    <div>
      <v-row>
        <v-col cols="auto">
          <v-icon class="mt-4">mdi-account</v-icon>
        </v-col>

        <v-col>
          <v-row dense>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getIdByName('lastName')"
                ref="lastName"
                v-model="formData.lastName"
                :label="$t('lastName')"
                filled
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getIdByName('firstName')"
                ref="firstName"
                v-model="formData.firstName"
                :label="$t('firstName')"
                filled
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getIdByName('mrn')"
                ref="mrn"
                v-model="formData.mrn"
                :label="$t('mrn')"
                filled
                :rules="formData.hasDci ? [] : [validationRules.maxLength(100)]"
                :disabled="formData.hasDci"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <DateTimePickerField
                ref="birthDate"
                v-model="formData.birthDate"
                :label="$t('birthDate')"
                picker-type="date"
                :class="formData.hasDci ? '' : 'required-indicator'"
                :disabled="formData.hasDci"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getIdByName('nasm')"
                ref="nasm"
                v-model="formData.nasm"
                :label="$t('nasm')"
                filled
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required, isNasmValid]"
                :disabled="formData.hasDci"
                @change="nasmChanged()"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-select
                ref="gender"
                v-model="formData.gender"
                :items="genders"
                :label="$t('gender')"
                item-text="name"
                item-value="id"
                filled
                :no-data-text="$t('noDataAvailable')"
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
              />
            </v-col>

            <v-col>
              <v-select
                ref="language"
                v-model="formData.language"
                :items="languages"
                :label="$t('language')"
                item-text="name"
                item-value="id"
                filled
                :no-data-text="$t('noDataAvailable')"
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-0">
            <v-col>
              <v-checkbox ref="active" v-model="formData.active" :label="$t('active')" class="mt-0" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <v-icon class="mt-4">mdi-home</v-icon>
        </v-col>

        <v-col>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getIdByName('address')"
                ref="address"
                v-model="formData.address"
                :label="$t('address')"
                filled
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
              />

              <v-text-field
                :id="getIdByName('city')"
                ref="city"
                v-model="formData.city"
                :label="$t('city')"
                filled
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
              />

              <v-row dense>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                  <v-text-field
                    :id="getIdByName('postalCode')"
                    ref="postalCode"
                    v-model="formData.postalCode"
                    :label="$t('postalCode')"
                    filled
                    :class="formData.hasDci ? '' : 'required-indicator'"
                    :rules="formData.hasDci ? [] : [validationRules.required]"
                    :disabled="formData.hasDci"
                  />
                </v-col>

                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                  <v-select
                    ref="country"
                    v-model="formData.countryId"
                    :items="countries"
                    :label="$t('country')"
                    item-text="name"
                    item-value="id"
                    filled
                    :no-data-text="$t('noDataAvailable')"
                    :class="formData.hasDci ? '' : 'required-indicator'"
                    :rules="formData.hasDci ? [] : [validationRules.required]"
                    :disabled="formData.hasDci"
                    @input="provincesScopeChanged(true)"
                  />
                </v-col>

                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                  <v-select
                    ref="province"
                    v-model="formData.provinceId"
                    :items="provinces"
                    :label="provinceLabel"
                    item-text="name"
                    item-value="id"
                    filled
                    :no-data-text="$t('noDataAvailable')"
                    :class="formData.hasDci ? '' : 'required-indicator'"
                    :rules="formData.hasDci ? [] : [validationRules.required]"
                    :disabled="formData.hasDci"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <v-icon class="mt-4">mdi-card-account-details</v-icon>
        </v-col>

        <v-col>
          <v-row dense>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getIdByName('phone')"
                ref="phone"
                v-model="formData.phone"
                filled
                :label="$t('phone')"
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-text-field
                :id="getIdByName('email')"
                ref="email"
                v-model="formData.email"
                :label="$t('email')"
                filled
                :rules="formData.hasDci ? [] : [validationRules.email]"
                validate-on-blur
                :disabled="formData.hasDci"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <v-icon class="mt-4">mdi-hospital-building</v-icon>
        </v-col>

        <v-col>
          <v-row dense>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-select
                ref="patientType"
                v-model="formData.patientType"
                :items="patientTypes"
                :label="$t('patientType')"
                item-text="name"
                item-value="id"
                filled
                :no-data-text="$t('noDataAvailable')"
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
                @input="organizationsScopeChanged(true)"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-select
                ref="organization"
                v-model="formData.organizationId"
                :items="organizations"
                :label="$t('organization')"
                item-text="name"
                item-value="id"
                filled
                :no-data-text="$t('noDataAvailable')"
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
                @input="facilitiesScopeChanged(true)"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
              <v-select
                ref="facility"
                v-model="formData.facilityId"
                :items="facilities"
                :label="$t('facility')"
                item-text="name"
                item-value="id"
                filled
                :no-data-text="$t('noDataAvailable')"
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
                @input="roombedsScopeChanged(true)"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-if="hasRoombeds" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
              <v-select
                ref="roombed"
                v-model="formData.roombedId"
                :items="roombeds"
                :label="$t('roombed')"
                item-text="label"
                item-value="value"
                filled
                :no-data-text="$t('noDataAvailable')"
                :class="formData.hasDci ? '' : 'required-indicator'"
                :rules="formData.hasDci ? [] : [validationRules.required]"
                :disabled="formData.hasDci"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
              <DateTimePickerField
                ref="startDate"
                v-model="formData.startDate"
                :label="$t('monitoringStartDate')"
                picker-type="date"
                required
                :disabled="false"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
              <DateTimePickerField
                ref="endDate"
                v-model="formData.endDate"
                :label="$t('monitoringEndDate')"
                picker-type="date"
                read-only
                :disabled="!formData.endDate"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="patientId">
        <v-col cols="auto">
          <v-icon class="mt-4">mdi-table-edit</v-icon>
        </v-col>

        <v-col>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
              <detail-field
                :id="getIdByName('createdDate')"
                ref="createdDate"
                v-model="formData.createdDate"
                :label="$t('createdDate')"
              />
              <detail-field
                :id="getIdByName('createdBy')"
                ref="createdBy"
                v-model="formData.createdBy"
                :label="$t('createdBy')"
              />
              <detail-field
                :id="getIdByName('partnerCode')"
                ref="partnerCode"
                :value="$t(formData.partnerCode)"
                :label="$t('dataSource')"
              />
            </v-col>

            <v-col>
              <detail-field
                :id="getIdByName('modifiedDate')"
                ref="modifiedDate"
                v-model="formData.modifiedDate"
                :label="$t('modifiedDate')"
              />
              <detail-field
                :id="getIdByName('modifiedBy')"
                ref="modifiedBy"
                v-model="formData.modifiedBy"
                :label="$t('modifiedBy')"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <RequiredFieldsLegend />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import { removeDiacritics } from '@/utils/stringUtils';

export default {
  name: 'PatientInfoTab',
  mixins: [translation, accessibility, validationRulesMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    formDataProps: {
      type: Object,
      required: true,
      default: () => {},
    },
    genders: {
      type: Array,
      required: true,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
      default: null,
    },
    countries: {
      type: Array,
      required: true,
      default: null,
    },
    provinces: {
      type: Array,
      required: true,
      default: null,
    },
    provinceLabel: {
      type: String,
      required: true,
      default: null,
    },
    provincesScopeChanged: {
      type: Function,
      required: true,
      default: () => {},
    },
    patientTypes: {
      type: Array,
      required: true,
      default: null,
    },
    organizations: {
      type: Array,
      required: true,
      default: null,
    },
    organizationsScopeChanged: {
      type: Function,
      required: true,
      default: () => {},
    },
    facilities: {
      type: Array,
      required: true,
      default: null,
    },
    facilitiesScopeChanged: {
      type: Function,
      required: true,
      default: () => {},
    },
    hasRoombeds: {
      type: Boolean,
      required: true,
      default: null,
    },
    roombeds: {
      type: Array,
      required: true,
      default: null,
    },
    roombedsScopeChanged: {
      type: Function,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      formData: this.formDataProps,
      isProcessing: false,
    };
  },

  methods: {
    nasmChanged() {
      this.formData.nasm = this.formData.nasm.replace(/\s+/g, '').toUpperCase();
    },
    removeAccentsAndSpecialCharacters: function (name) {
      let updated_name = removeDiacritics(name);
      return updated_name.replace(/[ .'\\-]/g, '');
    },
    isNasmValid: function (nasm) {
      let isValid = true;
      let provinceCode = null;
      let genderCode = null;

      const province = this.provinces.find((province) => province.id === this.formData.provinceId);
      if (province) {
        provinceCode = province.code;
      }
      const gender = this.genders.find((gender) => gender.id === this.formData.gender);
      if (gender) {
        genderCode = gender.code;
      }

      if (
        provinceCode === 'QC' &&
        !(
          this.formData.provinceId == null ||
          this.formData.gender == null ||
          this.formData.firstName == '' ||
          this.formData.lastName == '' ||
          this.formData.birthDate == null
        )
      ) {
        if (nasm.length === 12) {
          let nasmLastName = nasm.substring(0, 3);
          let nasmFirstName = nasm.charAt(3);
          const nasmbirthDate = nasm.substring(4, 6);
          const nasmMonthOfBirth = nasm.substring(6, 8);
          const nasmDayOfBirth = nasm.substring(8, 10);
          const nasmOtherNumbers = nasm.substring(10, 12);

          const year = this.formData.birthDate.substring(2, 4);
          const month = parseInt(this.formData.birthDate.substring(5, 7));
          const day = this.formData.birthDate.substring(8, 10);

          let lastNameWithoutAccents = this.removeAccentsAndSpecialCharacters(this.formData.lastName);
          let lastNameFormatted = String(lastNameWithoutAccents.substring(0, 3).toUpperCase());
          let firstNameWithoutAccents = this.removeAccentsAndSpecialCharacters(this.formData.firstName);
          let firstNameFormatted = String(firstNameWithoutAccents.charAt(0).toUpperCase());

          switch (lastNameFormatted.length) {
            case 1:
              lastNameFormatted = lastNameFormatted + 'XX';
              break;
            case 2:
              lastNameFormatted = lastNameFormatted + 'X';
              break;
          }

          isValid =
            nasmLastName === lastNameFormatted &&
            nasmFirstName === firstNameFormatted &&
            nasmbirthDate === year &&
            nasmMonthOfBirth == (genderCode == 'W' ? month + 50 : month) &&
            nasmDayOfBirth === day &&
            /^\d{2}$/.test(nasmOtherNumbers) &&
            nasm.length === 12;
        } else {
          isValid = false;
        }
      }
      return isValid || this.$t('errorInvalidNasm');
    },
  },
};
</script>
