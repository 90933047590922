import LogHome from './LogHome.vue';

export default {
  moduleName: 'log',
  routes: [
    {
      path: '/log',
      name: 'LogHome',
      component: LogHome,
    },
  ],
};
